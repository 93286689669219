*{
    padding: 0px;
    margin: 0px;
    box-sizing: border-box;
    background-color: rgb(33 32 50);
}

.mainDiv{
    /* border:2px solid black; */
    margin-top: 80px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}
.logo_container{
    /* border:2px solid rgb(237, 230, 230);   */
    width: fit-content;
    height: fit-content;
    margin-bottom: 50px;
}